body {
  /* color:rgba(124, 255, 91, 0.949); */
  background: rgb(0, 0, 0);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  /* overflow: hidden; */
}
Link{
  text-decoration: none;
}
#root {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  /* overflow: hidden; */
}
.Main{
  position: relative;
}
.navbar{
  /* border: solid red 1px; */
  position: absolute;
  top:14px;
}
.homeWrapper {
  background: rgb(0, 0, 0);
  padding: 0.5vw;
  display: grid;
  grid-auto-flow: row dense;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1.5fr 3.5fr 1.5fr 1fr 1fr 1fr;
  gap: 0;
  width: calc(100vw - 1vw);
  max-width: calc(100vw - 1vw);
  height: calc(100vh - 1vw);
  max-height: calc(100vh - 1vw);
}

.homeWrapper-rightSideArt {
  grid-area: 1 / 6 / 7/ 9;
  background: url(./rightSideArt.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.474);
  
}
#homeWrapper-rightSideArt-approved div {
  margin: 20% 40% 0 0;
  opacity: 0.1;
  background: #0eea96;
  border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
  width: 90%;
  height: 70%;
}
.homeWrapper-name span {
  margin-left: 3vw;
}
.termsWrapper-name {
  position: absolute;
  top:3.5vh;
  left:3.5vw;
  text-decoration: none;
  color: #0179fa;
  font-family: 'Outfit', sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-size: 2rem;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.homeWrapper-name {
  text-decoration: none;
    grid-area: 1 / 1 / 2 / 9;
  color: #0179fa;
  font-family: 'Outfit', sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-size: 2rem;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.homeWrapper-slogen {
  grid-area: 2 / 1 / 3 / 6;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 3.5rem;
  /* border:solid green 1px; */
}
#homeWrapper-description-approved span {
  color: #0eea96;
  text-align: center;
  font-weight: 400;
  font-size: 1.5rem;
}
#emailWrapper-description-approved span {
  border: solid blue 1px;
  color: 'white';
  text-align: center;
  font-weight: 400;
  font-size: 1.5rem;
}
.homeWrapper-slogen div {
  margin-top: 4%;
  margin-left: 12%;
  background: white;
  border-radius: 20px;
  width: 5vw;
  height: 0.3vh;
}
.homeWrapper-slogen span {
  margin-left: 12%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  height: 80%;
}
.homeWrapper-description {
  grid-area: 3 / 1 / 4 / 6;
flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-size: 1.4rem;
  text-align: left;
  font-style: normal;
}
.terms{
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  z-index: 10000;
  position: relative;
  margin-top: 0;
  margin-left: 5%;
  color: #0179fa;

  font-weight: 300;
  font-size: 0.7rem;
  width: 100%;
  text-decoration: none;
  /* border:solid yellow 1px; */
}
.privacy{
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  z-index: 10000;
  position: relative;
  margin-top: 0;
  margin-left: 5%;
  color: #0179fa;

  font-weight: 300;
  font-size: 0.7rem;
  width: 100%;
  text-decoration: none;
  }
.homeWrapper-description span {
  /* border: solid red 1px;  */
  margin-left: 12%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 100%;
}
#homeWrapper-description-approved span {
  margin-left: 10%;
  justify-content: center;
}
.homeWrapper-input-wrapper-btn {
  grid-area: 4/ 1 / 6 / 6;
}
.homeWrapper-input-wrapper-btn a {
  color: white;
  font-family: 'Outfit', sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-size: 1rem;
  text-decoration: none;
  margin-top: 5%;
  margin-left: 10%;
  background: #0179fa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 80%;
  height: 50px;
}
#homeWrapper-input-wrapper-btn-logo {
  background: url(./logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 20px 20px 0;
  width: 10%;
  height: 80%;
}
.homeWrapper-description-background {
  pointer-events: none;
  grid-area: 2 / 1 / 7/ 6;
  /* border:solid #80007e 2px; */
}
.homeWrapper-description-background span {
  margin-left: 5%;
  border: solid rgba(255, 255, 255, 0.409) 0.5px;
  background-color: rgba(255, 255, 255, 0.01);
  border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 100%;
}
.dot {
  background-color: #0eea96;
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  margin: 0 2vh;
  animation: beat 1s infinite linear;
  animation-delay: calc(0.1s * var(--i));
}
li{
  margin-bottom:2vh;
}
.dot:nth-child(1) {
  --i: 0;
}
.dot:nth-child(2) {
  --i: 1;
}
.dot:nth-child(3) {
  --i: 2;
}
.dot:nth-child(4) {
  --i: 3;
}
.dot:nth-child(4) {
  --i: 4;
}
.homeWrapper-powered {
  z-index: 1000000000000;
  position: fixed;
  bottom: 20px; /* Adjust the value as needed */
  left: 50%;
  transform: translateX(-50%);
  color: #0eea96;
  font-size: 12px; /* Adjust the font size if necessary */
  text-align: center;
  width: 90%;
}
Link{
  cursor: pointer;
}
small{
  color: #0eea96;
}
@keyframes beat {
  0% {
    transform: scale(0.8);
    opacity: 0.3;
  }
  20% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  40% {
    transform: scale(1.6);
    opacity: 1;
  }
  60% {
    transform: scale(1.6);
    opacity: 1;
  }
  80% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.3;
  }
}
.authChangeWrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.emailWrapper-instruction {
  color: white;
  text-align: center;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-optical-sizing: auto;
  font-size: 1.5rem;
  position: absolute;
  top: 38vh;
  border: solid blue 1px;
  width: 63vw;
}
.authChangeWrapper-input::placeholder {
  opacity: 0.2;
  color: black;
}
.authChangeWrapper-input {
  outline: none;
  color: black;
  background: white;
  font-family: 'Outfit', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-size: 1.1rem;
  display: block;
  padding: 10px 10px 10px 20px;
  margin: 10px;
  position: absolute;
  left: 5vw;
  border: 2px solid white;
  border-radius: 10px;
  width: 30vw;
  height: 30px;
}
.old {
  top: 38vh;
}
.new {
  top: 45vh;
}

.authChange-submit-btn-wrapper {
  background-color: #0179fa;
  position: absolute;
  top: 58vh;
  left: 5.46vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 10px;
  overflow: hidden;
  width: 31.6vw;
  height: 52px;
}
.authChange-submit-btn {
  color: white;
  font-family: 'Outfit', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 40px;
  height: 40px;
}
#authChange-layer {
  margin-left: 5%;
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 5vh;
  left: 5vw;
  border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 90%;
}

.authChange-err-msg-wrapper {
  color: #0eea96;
  font-family: 'Outfit', sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.2rem;
  position: absolute;
  top: 70vh;
  left: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 60vw;
}
.authChange-msg-wrapper {
  color: #0eea96;
  font-family: 'Outfit', sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.5rem;
  position: absolute;
  top: 55vh;
  left: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 60vw;
}
.termsWrapper {
  padding-top: 25vh;
  padding-bottom: 10vh;
  font-style: normal;
  display: flex;
  justify-content:center;
  align-items: flex-start;
  position: relative;
  overflow-y: scroll;
  /* border:solid red 1px; */
  width: 100vw;
  
}
.terms-container {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  margin: 10hv auto auto auto;
  /* border:solid blue 1px; */
  color: white;
}

.terms-content-container {
  padding-bottom: 20px;
}

.terms-title {
  font-size: 30px;
  font-family: 'Roboto-Bold', sans-serif;
  margin-bottom: 6vh;
}

.terms-index {
  font-size: 20px;
  font-family: 'Roboto-Bold', sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
}

.terms-text {
  font-size: 14px;
  font-family: 'Roboto-Regular', sans-serif;
  margin-bottom: 10px;
}

.terms-contact {
  font-size: 20px;
  font-family: 'Roboto-Bold', sans-serif;
  margin-top: 30px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 932px) and (orientation: landscape) {
  /* iPhone X, iPhone 11, etc., in landscape */

  .homeWrapper-name {
    font-size: 1.2rem;
  }
  .homeWrapper-slogen {
    font-size: 1.2rem;
  }
  .homeWrapper-slogen div {
    margin-top: 8%;
  }
  .homeWrapper-description {
    font-size: 0.7rem;
  }
  .homeWrapper-input-wrapper-btn {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .homeWrapper-input-wrapper-btn a {
    margin: 0 0 2% 0;
    font-size: 0.6rem;
    border-radius: 39% 61% 55% 45% / 26% 44% 56% 74%;
    width: 50px;
    height: 50px;
  }
  #homeWrapper-input-wrapper-btn-logo {
    width: 45px;
    height: 45px;
  }
  #homeWrapper-input::placeholder {
    opacity: 0.6;
    font-size: 0.8rem;
  }
  .beat-loader {
    top: 38%;
    left: 10%;
  }
  .authWrapper-msg {
    font-size: 1.2rem;
    left: 10%;
  }
  .dot {
    width: 3.5vh;
    height: 3.5vh;
  }
  #homeWrapper-description-approved span {
    color: white;
    font-size: 1rem;
  }
  .authChangeWrapper-input::placeholder {
    font-size: .7rem;
  }
  .authChangeWrapper-input {
  
    font-size: 0.9rem;
    padding: 0px 10px 0px 20px;
    border: 1px solid white;
  }
  .old {
    top: 30vh;
  }
  .new {
    top: 41vh;
  }
  .authChange-submit-btn-wrapper {
    top: 58vh;
    left: 6vw;
    padding: 0px 15.5px 0px 15.5px;
    height: 30px;
    width: 31vw;
  }
  .authChange-submit-btn {
    font-size: .8rem;
  }
  #authChange-layer {
    left: 3vw;
    width: 90%;
  }
  .authChange-err-msg-wrapper {
    font-size: .8rem;
    top: 80vh;
    left: 12vw;
  }
  .authChange-msg-wrapper {
    top: 58vh;
    left:3vw;
  }
  .homeWrapper-powered {

    font-size: 10px; 
  }

}
@media only screen and (min-device-width: 375px) and (max-device-width: 766px) and (orientation: portrait) {
  /* iPhone X, iPhone 11, etc., in portrait */
  .homeWrapper {
  }
  .homeWrapper-rightSideArt {
    grid-area: 2 / 1 / 5/ 9;
  }
  #homeWrapper-rightSideArt-approved {
    grid-area: 1 / 1 / 4 / 9;
  }
  .homeWrapper-name {
    font-size: 1.2rem;
  }
  .homeWrapper-slogen {
    grid-area: 2 / 1 / 2 / 9;
    font-size: 1.4rem;
  }
  .homeWrapper-slogen div {
    margin-top: 0%;
  }
  .homeWrapper-description {
    
    grid-area: 4 / 1 / 5 / 9;
    font-size: 0.66rem;
  }
  .homeWrapper-input-wrapper-btn {
    grid-area: 5/ 1 / 6 / 9;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .homeWrapper-input-wrapper-btn a {
    font-size: 0.55rem;
    margin: 0;
    border-radius: 30% 61% 55% 45% / 26% 44% 56% 74%;
    width:  55px;
    height: 55px;
  }
  #homeWrapper-input-wrapper-btn-logo {
    width: 45px;
    height: 45px;
  }
  .homeWrapper-description-background {
    z-index: 100;
    /* opacity:0; */
    grid-area: 2/ 1 / 5/ 9;
    /* background-color: #0eea96;  */
  }
  .homeWrapper-description-background span {
    /* opacity:0; */
    border: solid #0eea9648 0.5px;
    background-color: #0eea9618;
  }
  #homeWrapper-input::placeholder {
    opacity: 0.6;
    font-size: 0.8rem;
  }
  .dot {
    width: 1.5vh;
    height: 1.5vh;
  }
  #homeWrapper-description-approved {
    grid-area: 4 / 1 / 7 / 9;
  }
  #homeWrapper-description-approved span {
    color: white;
    font-size: 1rem;
    height: 80%;
  }
  #homeWrapper-rightSideArt-approved div {
    width: 100%;
  }
  .authChangeWrapper-input {
    left:20vw;
    font-size: 0.6rem;
    padding: 0px 10px 0px 20px;
    border: 1px solid white;
  }
  .old {
    top: 60vh;
  }
  .new{
    top: 64.5vh;
  }
  .authChange-submit-btn-wrapper {
    top: 74vh;
    left: 22.5vw;
    padding: 0px 15.5px 0px 15.5px;
    height: 33px;
    width: 30vw;
  }
  .authChange-submit-btn {
    font-size: .8rem;
  }
  #authChange-layer {
    background-color: transparent;
    /* background: rgba(255, 255, 255, 0.08); */
    left: 3vw;
    top: 0;
    width: 85%;
    height: 100%;
  }
  .authChange-err-msg-wrapper {
    font-size: .8rem;
    top: 81vh;
    left: 12vw;
  }
  .authChange-msg-wrapper {
    font-size: .8rem;
    left:18vw;
  }
  .homeWrapper-powered {

    font-size: 8px; 
  }
  .terms{
    z-index: 10000000;
    font-weight: 300;
    font-size: 0.6rem;

    }
  .privacy{
    z-index: 100000;
    font-weight: 300;
    font-size: 0.6rem;
    }
    .homeWrapper-powered {
      bottom: 35px;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 899px) and (orientation: portrait) {
  /* Styles for iPad in portrait orientation */
  .homeWrapper {
  }
  .homeWrapper-rightSideArt {
    grid-area: 2 / 1 / 5 / 9;
  }
  #homeWrapper-rightSideArt-approved {
    grid-area: 2 / 1 / 5 / 9;
  }
  .homeWrapper-name {
    font-size: 1.4rem;
  }
  .homeWrapper-slogen {
    grid-area: 2 / 1 / 2 / 9;
    font-size: 1.8rem;
  }
  .homeWrapper-slogen div {
    margin-top: 0%;
  }
  .homeWrapper-description {
    grid-area: 4 / 1 / 4 / 9;
    font-size: 1rem;
  }
  .homeWrapper-input-wrapper-btn {
    grid-area: 4/ 1 / 6 / 9;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .homeWrapper-input-wrapper-btn a {
    margin: 0;
  }
  #homeWrapper-input-wrapper-btn-logo {
    width: 45px;
    height: 45px;
  }
  .homeWrapper-description-background {
    pointer-events: none;
    grid-area: 1/ 1 / 7/ 9;
  }
  #homeWrapper-input::placeholder {
    opacity: 0.6;
    font-size: 0.8rem;
  }
  .beat-loader {
    top: 38%;
    left: 10%;
  }
  .authWrapper-msg {
    font-size: 1.2rem;
    left: 10%;
  }
  .dot {
    width: 2vh;
    height: 2vh;
  }
  #homeWrapper-description-approved {
    grid-area: 4 / 1 / 7 / 9;
    /* border: solid blue 1px; */
  }
  #homeWrapper-description-approved span {
    color: white;
    /* border: solid blue 1px; */
    font-size: 1.3rem;
    height: 80%;
  }
  #homeWrapper-rightSideArt-approved div {
    opacity: 0.08;
    margin: 10% 40% 0 0;
    width: 100%;
    height: 100%;
  }
  .authChangeWrapper-input {
    left:23vw;
    font-size: 0.8rem;
    padding: 3px 10px 3px 20px;
    border: 1px solid white;
  }
  .old {
    top: 50vh;
  }
  .new{
    top: 55vh;
  }
  .authChange-submit-btn-wrapper {
    top: 65vh;
    left: 24.3vw;
    padding: 0px 15.5px 0px 15.5px;
    height: 38px;
    width: 30vw;
  }
  .authChange-submit-btn {
    font-size: .9rem;
  }
  #authChange-layer {
    background: rgba(255, 255, 255, 0.08);
    left: 3vw;
    top: 0;
    width: 85%;
    height: 100%;
  }
  .authChange-err-msg-wrapper {
    top: 80vh;
    left: 12vw;
    /* border: solid blue 1px;s */
  }
  .authChange-msg-wrapper {
    left:18vw;
  }
  .homeWrapper-powered {

    font-size: 8px; 
  }
  .terms{
    z-index: 10000000;
    font-weight: 300;
    font-size: 0.6rem;

    }
  .privacy{
    z-index: 100000;
    font-weight: 300;
    font-size: 0.6rem;
    }
    .homeWrapper-powered {
      bottom: 35px;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  /* Styles for iPad in landscape orientation */

  .homeWrapper-name {
    font-size: 1.2rem;
  }
  .homeWrapper-slogen {
    font-size: 2rem;
  }
  .homeWrapper-slogen div {
    margin-top: 8%;
  }
  .homeWrapper-description {
    font-size: 1.2rem;
  }
  .homeWrapper-input-wrapper-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #homeWrapper-input-wrapper-btn-logo {
    width: 45px;
    height: 45px;
  }
  #homeWrapper-input::placeholder {
    opacity: 0.6;
    font-size: 0.8rem;
  }
  .beat-loader {
    top: 38%;
    left: 10%;
  }
  .authWrapper-msg {
    font-size: 1.2rem;
    left: 10%;
  }
  .dot {
    width: 2.5vh;
    height: 2.5vh;
  }
  #homeWrapper-description-approved span {
    color: white;
    font-size: 1.5rem;
  }
  .authChangeWrapper-input {
    font-size: 0.9rem;
    padding: 3px 10px 3px 20px;
    border: 1px solid white;
  }
  .old {
    top: 37.5vh;
  }
  .authChange-submit-btn-wrapper {
    top: 58vh;
    left: 6vw;
    padding: 0px 15.5px 0px 15.5px;
    height: 38px;
    width: 30vw;
  }
  #authChange-layer {
    left: 3vw;
    width: 90%;
  }
  .authChange-err-msg-wrapper {
    top: 80vh;
    left: 12vw;
  }
  .authChange-msg-wrapper {
    top: 58vh;
    left:3vw;
    /* border: solid blue 1px; */
  }
  .homeWrapper-powered {

    font-size: 8px; 
  }
}
