/* Assuming you have variables defined */
:root {
  --primary-color: #0179fa;
  --background-color: #000;
  --text-color: #fff;
  --error-color: #dc3545;
  --success-color: #0eea96;
}

/* Contact Form Container */
.contactForm {
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  /* ... rest of the styles */
}

/* Form Fields */
.contactForm input[type="text"],
.contactForm input[type="email"],
.contactForm textarea {
  border: 1px solid var(--primary-color);
  color: var(--text-color);
  /* ... rest of the styles */
}

/* Submit Button */
.contactForm button[type="submit"] {
  background-color: var(--primary-color);
  /* ... rest of the styles */
}

/* Success/Error Message */
.contactForm .statusMessage.success {
  color: var(--success-color);
}

.contactForm .statusMessage.error {
  color: var(--error-color);
}

/* ... rest of the styles */
